

























































































import { Vue, Component } from "vue-property-decorator";
import { MetaModule, ReportModule } from "../../../store/modules";
import { ReportParam } from "../../../store/models/report";
import helper from "@/utils/helpers";

@Component({
  components: {}
})
export default class AdminFilters extends Vue {
  toDate: Date[] = [];

  filterObj: ReportParam = {
    district: [],
    palika: [],
    incident_category: [],
    fromdate: "",
    todate: "",
    status: ""
  } as ReportParam;

  municipality: any[] = [];
  async created() {
    this.loadData();
  }

  onFilterClicked(e: any) {
    this.loadData();
  }

  async loadData() {
    if (!this.toDate) {
      this.toDate = [];
    }
    if (this.toDate.length > 0) {
      this.filterObj.fromdate = this.toDate[0].toDateString();
      this.filterObj.todate = this.toDate[1].toDateString();
    }

    await ReportModule.getIncidents(this.filterObj);

    ReportModule.setWeeklyData(ReportModule.toDate);
    this.toDate[0] = ReportModule.fromDate;
    this.toDate[1] = ReportModule.toDate;
  }
  get incidents() {
    return ReportModule.incidents;
  }
  get districts() {
    return MetaModule.districtList;
  }
  async onDistrictChange(e: any) {
    this.municipality = await MetaModule.loadMuncipality(e);
  }
  get categories() {
    return MetaModule.incidentCategories;
  }

  get getFromDate() {
    return ReportModule.fromDate.toDateString();
  }

  get getToDate() {
    return ReportModule.toDate.toDateString();
  }

  clearFilter() {
    this.filterObj = {
      district: "",
      palika: [],
      incident_category: [],
      fromdate: "",
      todate: ""
    };
    this.toDate = [];
    this.loadData();
  }
  downloadExcel(e: any) {
    helper.JSONToCSVConvertor(this.incidents, "Incident Record", true);
  }
}
